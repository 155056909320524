import React from 'react';
import Nav from './Nav';
import WidgetSeven from './WidgetSeven';
import Footer from './Footer'
import MonthlyTargetCharts from './MonthlySalesTarget';
import YesterdayOrders from './YesterdayOrders';
import SalesTargetsValue from './SalesTargetsValue';

const CombinedSummary = () => {
    return (
        <>

            <div className="combined-widgets-container wrapper">
                <div className="summary-widget-container orders" >
                    <WidgetSeven />
                </div>

                <div className="summary-widget target" style={{ background: '#FAF6EF' }}>
                    {/* <MonthlyTargetCharts /> */}
                    <SalesTargetsValue/>
                </div>

                <div className="widget-container summary-widget yesterday-orders">
                    <YesterdayOrders />
                </div>
            </div>
        </>

    );
};

export default CombinedSummary;