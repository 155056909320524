import React, { useRef, useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext/AuthContext'; // Adjust the path as necessary
import { auth, signOut } from './Firebase'; // Import signOut and auth instance
import logoutIcon from '../assets/log-out.svg';
import useWorkflowCheck from './AuthContext/UseWorkflowAccess';

const Nav = () => {
    const { currentUser } = useAuth();
    const location = useLocation(); // Get the current location
    const navigate = useNavigate(); // Get the navigate function
    const navRef = useRef(null);
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    // Check if the user has workflow access
    const hasWorkflowAccess = useWorkflowCheck(currentUser?.email);

    useEffect(() => {
        console.log('Current User Email:', currentUser?.email);
        console.log('Has Workflow Access:', hasWorkflowAccess);

        // Redirect to /summary if the user doesn't have workflow access and is trying to access /workflows
        if (!hasWorkflowAccess && location.pathname === '/Workflows') {
            navigate('/AllSummary');
        }
    }, [currentUser, hasWorkflowAccess, location.pathname, navigate]);

    // Determine if the current path is one of the warehouse paths
    const warehousePaths = ['/Receiving', '/majors', '/independents', '/Dropship'];
    const isWarehouseActive = warehousePaths.includes(location.pathname);

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            // Add any logic you need after successful sign out
        } catch (error) {
            console.error('Error signing out: ', error);
            // Handle errors if needed
        }
    };

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen); // Simply toggle the state
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen); // Toggle the dropdown state
    };

    return (
        <div className="nav" ref={navRef}>
            <h1>Kidcentral Dashboard</h1>
            <button className={`hamburger-menu ${isNavOpen ? 'open' : ''}`} onClick={toggleNav}>
                <span></span>
                <span></span>
                <span></span>
            </button>
            <div className={`nav-links ${isNavOpen ? 'active' : ''}`} ref={navRef}>
                {hasWorkflowAccess && (
                    <NavLink to="/workflows" className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'} onClick={() => setIsNavOpen(false)}>
                        The Boys
                    </NavLink>
                )}
                <NavLink to="/home" className={({ isActive }) => isActive ? 'nav-link home active' : 'nav-link home'} onClick={() => setIsNavOpen(false)}>
                    Home
                </NavLink>
                <div className="nav-item dropdown warehouse">
                    <span className={`nav-link ${isWarehouseActive ? 'active' : ''}`} onClick={toggleDropdown}>
                        Warehouse ⏷
                    </span>
                    <div className={`dropdown-content ${isDropdownOpen ? 'show' : ''}`}>
                        <NavLink to="/receiving" className={({ isActive }) => isActive ? 'active' : ''} onClick={() => setIsNavOpen(false)}>
                            Receiving
                        </NavLink>
                        <NavLink to="/majors" className={({ isActive }) => isActive ? 'active' : ''} onClick={() => setIsNavOpen(false)}>
                            Majors
                        </NavLink>
                        <NavLink to="/independents" className={({ isActive }) => isActive ? 'active' : ''} onClick={() => setIsNavOpen(false)}>
                            Independents
                        </NavLink>
                        <NavLink to="/dropship" className={({ isActive }) => isActive ? 'active' : ''} onClick={() => setIsNavOpen(false)}>
                            Dropship
                        </NavLink>
                    </div>
                </div>
                <NavLink to="/allsummary" className={({ isActive }) => isActive ? 'nav-link summary active' : 'nav-link summary'} onClick={() => setIsNavOpen(false)}>
                    Summary
                </NavLink>
                <a href="https://sites.google.com/kidcentral.ca/kidcentral-supply-intranet/?pli=1&authuser=4" className='nav-link hub' target="_blank" rel="noopener noreferrer">
                    HUB
                </a>
                {currentUser && (
                    <button onClick={handleSignOut} className="nav-link logout-button">
                        <img src={logoutIcon} alt="Logout" className="logoutIcon" />
                        <span className="logout-text">Logout</span>
                    </button>
                )}
            </div>
        </div>
    );
};

export default Nav;
