import React, { useState, useEffect } from 'react';
import workToDo from '../assets/weGotWorkToDo.gif'; // Ensure this path is correct
import useAdminCheck from './AuthContext/useAdminCheck';
import { useAuth } from './AuthContext/AuthContext';

function WidgetTwo() {
  const [orders, setOrders] = useState([]);
  const [displayOrders, setDisplayOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const { currentUser } = useAuth();
  const isAdmin = useAdminCheck(currentUser?.email);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        let response = await fetch(process.env.REACT_APP_API_MAJORS_2_PRIMARY);
        if (!response.ok) throw new Error('Network response was not ok');
        let data = await response.json();

        if (data && data.data && data.data.length > 0) {
          setOrders(data.data);
          initiateGlitchEffect(data.data);
        } else {
          throw new Error('No data returned');
        }
      } catch (error) {
        console.error('First fetch failed or no data:', error);
        try {
          let response = await fetch(process.env.REACT_APP_API_MAJORS_2_SECONDARY);
          if (!response.ok) throw new Error('Network response was not ok');
          let data = await response.json();

          if (data && data.data && data.data.length > 0) {
            setOrders(data.data);
            initiateGlitchEffect(data.data);
          } else {
            setOrders([]);
          }
        } catch (error) {
          console.error('Second fetch failed or no data:', error);
          setOrders([]);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 120000);

    return () => clearInterval(intervalId);
  }, []);

  const initiateGlitchEffect = (data) => {
    let steps = 10;
    let interval = 100;

    for (let i = 0; i <= steps; i++) {
      setTimeout(() => {
        const tempData = data.map(order => {
          let tempOrder = {};
          for (const key in order) {
            tempOrder[key] = i === steps ? order[key] : Math.floor(Math.random() * 1000000);
          }
          return tempOrder;
        });
        setDisplayOrders(tempData);
      }, i * interval);
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedData = [...displayOrders].sort((a, b) => {
      if (key === 'Sub Total after all Discounts' || key === 'Fill %' || key === 'No# of Detail lines') {
        const aValue = parseFloat(a[key]) || 0;
        const bValue = parseFloat(b[key]) || 0;
        if (aValue < bValue) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      }

      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setDisplayOrders(sortedData);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (orders.length === 0) {
    return <>
      <h2>Majors Completed 🥲</h2>
      <img src={workToDo} alt="Loading" className='loadingGif' />
    </>;
  }

  const calculateTotal = () => {
    return displayOrders.reduce((acc, order) => {
      return acc + parseFloat(order["Sub Total after all Discounts"] || 0);
    }, 0).toFixed(2);
  };

  const rowCount = displayOrders.length;

  return (
    <div className='widget four flex-item'>
      <div style={{ maxHeight: '700px', overflow: 'auto' }}>
        <div className="Header">
          <h2>Majors Completed</h2>
        </div>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              {['SO#', 'PO#', 'Customer Code', 'Order Date', 'Order Status', 'Ship Name', 'Requested Ship Date', ...(isAdmin ? ['Sub Total after all Discounts'] : []), 'Fill %', 'Current User', 'No# of Detail lines', 'Percentage of Cons'].map((key, index) => (
                <th
                  key={index}
                  style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }}
                  onClick={() => handleSort(key)}
                >
                  {key} {sortConfig.key === key ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {displayOrders.map((order, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid black' }}>{order["SO#"]}</td>
                <td style={{ border: '1px solid black' }}>{order["PO#"]}</td>
                <td style={{ border: '1px solid black' }}>{order["Customer Code"]}</td>
                <td style={{ border: '1px solid black' }}>{order["Order Date"]}</td>
                <td style={{ border: '1px solid black' }}>{order["Order Status"]}</td>
                <td style={{ border: '1px solid black' }}>{order["Ship Name"]}</td>
                <td style={{ border: '1px solid black' }}>{order["Requested Ship Date"]}</td>
                {isAdmin && <td style={{ border: '1px solid black' }}>${order["Sub Total after all Discounts"]}</td>}
                <td style={{ border: '1px solid black' }}>{order["Fill %"]}</td>
                <td style={{ border: '1px solid black' }}>{order["Current User"]}</td>
                <td style={{ border: '1px solid black' }}>{order["No# of Detail lines"]}</td>
                <td style={{ border: '1px solid black' }}>{order["Percentage of Cons"]}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td style={{ border: '1px solid black', textAlign: 'center' }}>Total SO's: {rowCount}</td>
              <td style={{ border: '1px solid black', textAlign: 'center' }} colSpan="6"></td>
              {isAdmin && (
                <>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>${calculateTotal()}</td>
                  <td style={{ border: '1px solid black', textAlign: 'center' }} colSpan="4"></td>
                </>
              )}
              {!isAdmin && <td style={{ border: '1px solid black', textAlign: 'center' }} colSpan="5"></td>}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

export default WidgetTwo;
