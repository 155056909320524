import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import Nav from './Nav';

function PurchaseOrderReview() {
    const [data, setData] = useState([]);
    const [sortDirection, setSortDirection] = useState({
        "Vendor Code": 'DESC',
        "Order Date": 'DESC',
        "Date Expected": 'DESC',
        "days before expected": 'DESC',
        "Status": 'DESC',
    });
    const [sortField, setSortField] = useState('days before expected');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [statusDropdownVisible, setStatusDropdownVisible] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_PURCHASE_ORDER_REVIEW_PRIMARY);
                if (!response.ok) throw new Error('Network response was not ok');
                const jsonData = await response.json();
                if (jsonData.data && jsonData.data.length > 0) {
                    setData(jsonData.data);
                } else {
                    throw new Error('No data returned');
                }
            } catch (error) {
                console.error('Fetch failed:', error);
                setData([]);
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 43200000);
        return () => clearInterval(intervalId);
    }, []);

    const sortData = (field, status = null) => {
        setSortField(field);
        const newDirection = sortDirection[field] === 'DESC' ? 'ASC' : 'DESC';
        const sortedData = [...data].sort((a, b) => {
            let aValue = a[field];
            let bValue = b[field];

            if (field === 'Date Expected' || field === 'Order Date') {
                aValue = new Date(aValue).getTime();
                bValue = new Date(bValue).getTime();
            } else if (!isNaN(Number(aValue)) && !isNaN(Number(bValue))) {
                aValue = Number(aValue);
                bValue = Number(bValue);
            }

            if (field === 'Status' && status) {
                if (aValue === status && bValue !== status) return -1;
                if (aValue !== status && bValue === status) return 1;
            }

            if (newDirection === 'DESC') {
                return aValue > bValue ? -1 : 1;
            } else {
                return aValue < bValue ? -1 : 1;
            }
        });

        setData(sortedData);
        setSortDirection({ ...sortDirection, [field]: newDirection });
    };

    const getSortIcon = (field) => {
        return sortField === field ? (sortDirection[field] === 'DESC' ? '⬇️' : '⬆️') : '↕️';
    };

    const handleStatusChange = (status) => {
        setSelectedStatus(status);
        sortData('Status', status);
        setStatusDropdownVisible(false);
    };

    const toggleStatusDropdown = () => {
        setStatusDropdownVisible(!statusDropdownVisible);
    };

    return (
        <>
            <div className='widget receiving flex-item wrapper'>
                <div className="widget-container" style={{ maxHeight: '93vh' }}>
                    <div className="Header">
                        <h2>Purchase Order Review</h2>
                    </div>
                    <table style={{ marginTop: '20px', width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th>PO Number</th>
                                <th onClick={() => sortData('Vendor Code')} style={{ cursor: 'pointer' }}>
                                    Vendor Code {getSortIcon('Vendor Code')}
                                </th>
                                <th>Type Of PO</th>
                                <th>Estimated Ship Date</th>
                                <th onClick={() => sortData('Date Expected')} style={{ cursor: 'pointer' }}>
                                    Date Expected {getSortIcon('Date Expected')}
                                </th>
                                <th>No of Detail lines</th>
                                <th>Ref Number</th>
                                <th onClick={() => sortData('days before expected')} style={{ cursor: 'pointer' }}>
                                    Days Before Expected {getSortIcon('days before expected')}
                                </th>
                                <th>Days Overdue</th>
                                <th onClick={toggleStatusDropdown} style={{ cursor: 'pointer',  }}>
                                    Status {getSortIcon('Status')}
                                    {statusDropdownVisible && (
                                        <div style={{
                                            position: 'absolute',
                                            fontSize:'13px',
                                            backgroundColor: 'white',
                                            border: '1px solid #ccc',
                                            zIndex: 1000,
                                            marginTop: '5px',
                                            maxHeight: '150px',
                                            overflowY: 'auto'
                                        }}>
                                            <ul style={{ listStyleType: 'none', padding: '10px', margin: 0 }}>
                                                {Array.from(new Set(data.map(item => item.Status))).map((status, index) => (
                                                    <li key={index} style={{ cursor: 'pointer', padding: '5px 10px' }} onClick={() => handleStatusChange(status)}>
                                                        {status}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index} style={{ backgroundColor: item["Status"] === "PO-IN TRANSIT" ? 'yellow' : 'transparent' }}>
                                    <td>{item["PO Number"]}</td>
                                    <td>{item["Vendor Code"]}</td>
                                    <td>{item["Type Of PO"]}</td>
                                    <td>{item["Estimated ship Date"]}</td>
                                    <td>{item["Date Expected"]}</td>
                                    <td>{item["No of Detail lines"]}</td>
                                    <td>{item["ref number"] || '-'}</td>
                                    <td>{item["days before expected"]}</td>
                                    <td>{item["days overdue"]}</td>
                                    <td>{item["Status"]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default PurchaseOrderReview;
