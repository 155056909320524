// Create a new file, e.g., CombinedWidgets.js in your Components directory

import React from 'react';
import Nav from './Nav';
import WidgetOne from './WidgetOne';
import WidgetTwo from './WidgetTwo';
import Footer from './Footer'

const CombinedMajors = () => {
  return (
    <>

    <div className="combined-widgets-container wrapper">
      <div className="widget-container top">
        <WidgetOne />
      </div>
      <div className="gradient-divider"></div> {/* Divider element */}

      <div className="widget-container bottom">
        <WidgetTwo />
      </div>
    </div>
    </>

  );
};

export default CombinedMajors;
