import './App.css';
import { useLocation } from 'react-router-dom';
import CombinedMajors from './Components/Majors';
import CombinedIndependent from './Components/Independents';
import CombinedDropship from './Components/Dropship';
import WidgetSeven from './Components/WidgetSeven';
import Login from './Components/Auth';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './Components/AuthContext/AuthContext';
import ProtectedRoute from './Components/AuthContext/ProtectedRoutes';
import CombinedSummary from './Components/Summary';
import PurchaseOrderReview from './Components/PurchaseOrderReview';
import MonthlyTargetChart from './Components/MonthlySalesTarget';
import YesterdayOrders from './Components/YesterdayOrders';
import Announcements from './Components/Announcements';
import HomePage from './Components/Home';
import Nav from './Components/Nav';
import Footer from './Components/Footer';
import Workflows from './Components/Workflows';

function App() {
  const location = useLocation();
  const hideFooterOnLogin = location.pathname === '/';

  return (
    <div className="App">
      <AuthProvider>
        {location.pathname !== '/' && <Nav />}
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/Majors' element={<ProtectedRoute element={<CombinedMajors />} />} />
          <Route path='/Independents' element={<ProtectedRoute element={<CombinedIndependent />} />} />
          <Route path='/Dropship' element={<ProtectedRoute element={<CombinedDropship />} />} />
          <Route path='/Summary' element={<ProtectedRoute element={<WidgetSeven />} />} />
          <Route path='/*' element={<ProtectedRoute element={<WidgetSeven />} />} />
          <Route path='/AllSummary' element={<ProtectedRoute element={<CombinedSummary />} />} />
          <Route path='/Receiving' element={<ProtectedRoute element={<PurchaseOrderReview />} />} />
          <Route path='/MonthlyTargets' element={<ProtectedRoute element={<MonthlyTargetChart />} />} />
          <Route path='/YesterdaysOrders' element={<ProtectedRoute element={<YesterdayOrders />} />} />
          <Route path='/Announcements' element={<ProtectedRoute element={<Announcements />} />} />
          <Route path='/Home' element={<ProtectedRoute element={<HomePage />} />} />
          <Route path='/Workflows' element={<ProtectedRoute element={<Workflows />} />} />
        </Routes>
        {!hideFooterOnLogin && <Footer />}
      </AuthProvider>
    </div>
  );
}

export default App;
