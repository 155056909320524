import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Announcements from "./Announcements";
import WidgetOne from './WidgetOne';
import WidgetTwo from './WidgetTwo';
import WidgetThree from './WidgetThree';
import WidgetFour from './WidgetFour';
import WidgetFive from './WidgetFive';
import WidgetSix from './WidgetSix';
import WidgetSeven from './WidgetSeven';
import SalesTargetsValue from './SalesTargetsValue';
import YesterdayOrders from './YesterdayOrders';

const components = [
    WidgetOne,
    WidgetTwo,
    WidgetThree,
    WidgetFour,
    WidgetFive,
    WidgetSix,
    WidgetSeven,
    SalesTargetsValue,
    YesterdayOrders
];

const HomePage = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [announcementIndex, setAnnouncementIndex] = useState(0);
    const [announcements, setAnnouncements] = useState([]); // Assuming you fetch announcements from an API or have a static list
    let navigate = useNavigate(); // useNavigate hook for navigation in React Router v6

    useEffect(() => {
        // Fetch announcements from an API or define them statically
        // Example:
        setAnnouncements([
            { id: 1, title: 'Announcement 1', content: 'Content of announcement 1' }
            // Add more announcements as needed
        ]);

        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % components.length);
        }, 30000); // Change slide every 25 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setAnnouncementIndex((prevIndex) => (prevIndex + 1) % announcements.length);
        }, 20000); // Change announcements every 10 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [announcements.length]);

    const CurrentComponent = components[currentIndex];

    const goToAnnouncements = () => {
        navigate('/announcements'); // Programmatically navigate to the announcements page
    };

    return (
        <>
            <div className="wrapper">
                <div className="slideshow-container">
                    <CurrentComponent />
                </div>
                <div className="teaser-container">
                    <div className="teaser-card" onClick={goToAnnouncements}>
                        <h2>Latest Announcements</h2>
                        {announcements.length > 0 ? (
                            <Announcements preview announcementIndex={announcementIndex} />
                        ) : (
                            <p>No announcements available</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomePage;
