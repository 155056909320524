// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { 
  getAuth, 
  signInWithEmailAndPassword, 
  setPersistence, 
  browserLocalPersistence,
  GoogleAuthProvider, // Import GoogleAuthProvider
  signInWithPopup,
  signOut // Import signInWithPopup for Google sign-in
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBbMmsOr4323P3lh5umBGSG-YtijjrOyC8",
  authDomain: "kidscentral-dashboard.firebaseapp.com",
  projectId: "kidscentral-dashboard",
  storageBucket: "kidscentral-dashboard.appspot.com",
  messagingSenderId: "835181547575",
  appId: "1:835181547575:web:5799f7cfdd488d845defb2",
  measurementId: "G-5Q0VR7K2EW"
};

// Initialize Firebase
const dbApp = initializeApp(firebaseConfig);
const auth = getAuth(dbApp);  
setPersistence(auth, browserLocalPersistence);

// Export the necessary functions and instances
export { auth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup , signOut};