import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

// Define the custom plugin outside of the component to avoid redefinition on each render
const valueOnTopPlugin = {
    id: 'valueOnTopPlugin',
    afterDatasetsDraw: (chart, args, options) => {
        const ctx = chart.ctx;
        ctx.save();
        ctx.font = 'bold 12px Arial';
        ctx.fillStyle = 'black';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        chart.data.datasets.forEach((dataset, i) => {
            const meta = chart.getDatasetMeta(i);
            if (!meta.hidden) {
                meta.data.forEach((element, index) => {
                    // Check if the value is defined
                    const value = dataset.data[index];
                    if (value !== undefined && value !== null) {
                        ctx.fillStyle = options.textColor || 'black'; // You can set the text color
                        const fontSize = options.fontSize || 12; // Set the font size
                        ctx.font = `${fontSize}px sans-serif`;
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'bottom';
                        const dataString = value.toString();
                        // Position the text slightly above the bar
                        ctx.fillText(dataString, element.x, element.y - 5);
                    }
                });
            }
        });
        ctx.restore();
    }
};

// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const YesterdayOrders = () => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_YESTERDAY_ORDERS_PRIMARY);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                if (jsonData.data && jsonData.data.length > 0) {
                    const data = jsonData.data[0];
                    setChartData({
                        labels: ['# of completed pick Majors', '# of completed pick independent', 'Dropship', 'Independent', 'Majors'],
                        datasets: [{
                            label: 'Order Counts',
                            data: [
                                data["# of completed pick Majors"],
                                data["# of completed pick independent"],
                                data["Dropship"],
                                data["Independent"],
                                data["Majors"]
                            ],
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.6)',
                                'rgba(54, 162, 235, 0.6)',
                                'rgba(255, 206, 86, 0.6)',
                                'rgba(75, 192, 192, 0.6)',
                                'rgba(153, 102, 255, 0.6)'
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)'
                            ],
                            borderWidth: 1
                        }]
                    });
                } else {
                    console.error('No data returned');
                }
            } catch (error) {
                console.error('Fetch failed:', error);
            }
        };

        fetchData();
        // Set up the interval to call fetchData every 12 hours
        const intervalId = setInterval(fetchData, 43200000); // 12 hours in milliseconds

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    // Options must be defined inside the component to access isMobile function
    const isMobile = () => window.innerWidth < 600;
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    padding: 20,
                    boxWidth: 10,
                    font: {
                        size: isMobile() ? 10 : 14,
                    }
                }
            },
            title: {
                display: true,
                text: "Yesterday's Order Counts",
                font: {
                    size: isMobile() ? 16 : 20,
                }
            },
            // Register the plugin with its options here
            valueOnTopPlugin: {
                textColor: 'black',
                fontSize: isMobile() ? 10 : 12,
            }
        },
        scales: {
            x: {
                ticks: {
                    font: {
                        size: isMobile() ? 10 : 14,
                    }
                }
            },
            y: {
                ticks: {
                    font: {
                        size: isMobile() ? 10 : 14,
                    }
                }
            }
        }
    };

    // Now, register the plugin after options to ensure access to isMobile
    ChartJS.register(valueOnTopPlugin);

    return (
        <>
            <div style={{ padding: '20px', height: '400px' }}>
                <h2 style={{ textDecoration: 'underline' }}>Yesterday's Orders Summary</h2>
                <Bar data={chartData} options={options} />
            </div>
        </>
    );
};

export default YesterdayOrders;



