import React, { useState, useEffect } from 'react';
import loadingGif from '../assets/slothGif.gif'; // Ensure this path is correct
import useAdminCheck from './AuthContext/useAdminCheck';
import { useAuth } from './AuthContext/AuthContext';

function WidgetOne() {
  const [orders, setOrders] = useState([]);
  const [displayOrders, setDisplayOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const { currentUser } = useAuth();
  const isAdmin = useAdminCheck(currentUser?.email);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        let response = await fetch(process.env.REACT_APP_API_MAJORS_1_PRIMARY, {
          method: 'GET',
          headers: {
            'Origin': 'https://dashboard.kidcentralsupply.ca'
          },
        });
        if (!response.ok) throw new Error('Network response was not ok');
        let data = await response.json();

        if (data && data.data && data.data.length > 0) {
          setOrders(data.data);
          initiateGlitchEffect(data.data);
        } else {
          throw new Error('No data returned');
        }
      } catch (error) {
        console.error('First fetch failed or no data:', error);
        try {
          let response = await fetch(process.env.REACT_APP_API_MAJORS_1_SECONDARY);
          if (!response.ok) throw new Error('Network response was not ok');
          let data = await response.json();

          if (data && data.data && data.data.length > 0) {
            setOrders(data.data);
            initiateGlitchEffect(data.data);
          } else {
            setOrders([]);
          }
        } catch (error) {
          console.error('Second fetch failed or no data:', error);
          setOrders([]);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 120000);

    return () => clearInterval(intervalId);
  }, []);

  const initiateGlitchEffect = (data) => {
    let steps = 10;
    let interval = 100;

    for (let i = 0; i <= steps; i++) {
      setTimeout(() => {
        const tempData = data.map(order => {
          let tempOrder = {};
          for (const key in order) {
            tempOrder[key] = i === steps ? order[key] : Math.floor(Math.random() * 1000000);
          }
          return tempOrder;
        });
        setDisplayOrders(tempData);
      }, i * interval);
    }
  };

  const getFillPriority = (order) => {
    if (order.FillPriority === 1) return 1; // Rush indi orders
    if (order.FillPriority === 2) return 2; // Rush major indi orders
    if (order.FillPriority === 3) return 3; // Rush major orders
    return 5; // Regular orders
  };

  const sortOrders = (orders) => {
    return orders.sort((a, b) => {
      const aFillPriority = getFillPriority(a);
      const bFillPriority = getFillPriority(b);

      if (aFillPriority !== bFillPriority) {
        return aFillPriority - bFillPriority; // Prioritize by FillPriority first
      }

      return 0;
    });
  };

  useEffect(() => {
    setDisplayOrders(sortOrders(orders));
  }, [orders]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedData = [...displayOrders].sort((a, b) => {
      if (key === 'Sub Total after all Discounts' || key === 'Fill %' || key === 'No# of Detail lines') {
        const aValue = parseFloat(a[key]) || 0;
        const bValue = parseFloat(b[key]) || 0;
        if (aValue < bValue) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      }

      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setDisplayOrders(sortedData);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (orders.length === 0) {
    return <img className='loadingGif' src={loadingGif} alt="Loading" />;
  }

  const calculateTotal = () => {
    return displayOrders.reduce((acc, order) => {
      return acc + parseFloat(order["Sub Total after all Discounts"] || 0);
    }, 0).toFixed(2);
  };

  const rowCount = displayOrders.length;

  return (
    <div className='widget one flex-item'>
      <div className="Header">
        <h2>Majors in progress</h2>
      </div>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('SO#')}>SO# {sortConfig.key === 'SO#' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
            <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('PO#')}>PO# {sortConfig.key === 'PO#' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
            <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Customer Code')}>Customer Code {sortConfig.key === 'Customer Code' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
            <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Order Date')}>Order Date {sortConfig.key === 'Order Date' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
            <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Order Status')}>Order Status {sortConfig.key === 'Order Status' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
            <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Ship Name')}>Ship Name {sortConfig.key === 'Ship Name' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
            <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Requested Ship Date')}>Requested Ship Date {sortConfig.key === 'Requested Ship Date' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
            {isAdmin && <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Sub Total after all Discounts')}>Sub Total after all Discounts {sortConfig.key === 'Sub Total after all Discounts' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>}
            <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Fill %')}>Fill % {sortConfig.key === 'Fill %' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
            <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Current User')}>Current User {sortConfig.key === 'Current User' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
            <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('No# of Detail lines')}>No# of Detail lines {sortConfig.key === 'No# of Detail lines' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
            <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Percentage of Cons')}>Percentage of Cons {sortConfig.key === 'Percentage of Cons' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
            <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Priority')}>Priority {sortConfig.key === 'Priority' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
          </tr>
        </thead>
        <tbody>
          {displayOrders.map((order, index) => {
            let rowStyle = {};
            let priorityText = "";

            if (order.FillPriority === 1) {
              rowStyle = { backgroundColor: 'red', color: 'white', fontWeight: 'bold' }; // Rush indi orders
              priorityText = "Rush";
            } else if (order.FillPriority === 2) {
              rowStyle = { backgroundColor: 'darkgreen', color: 'white', fontWeight: 'bold' }; // Rush major indi orders
              priorityText = "Rush";
            } else if (order.FillPriority === 3) {
              rowStyle = { backgroundColor: 'purple', color: 'white', fontWeight: 'bold' }; // Rush major orders
              priorityText = "Rush";
            }

            return (
              <tr key={index} style={rowStyle}>
                <td style={{ border: '1px solid black' }}>{order["SO#"]}</td>
                <td style={{ border: '1px solid black' }}>{order["PO#"]}</td>
                <td style={{ border: '1px solid black' }}>{order["Customer Code"]}</td>
                <td style={{ border: '1px solid black' }}>{order["Order Date"]}</td>
                <td style={{ border: '1px solid black' }}>{order["Order Status"]}</td>
                <td style={{ border: '1px solid black' }}>{order["Ship Name"]}</td>
                <td style={{ border: '1px solid black' }}>{order["Requested Ship Date"]}</td>
                {isAdmin && <td style={{ border: '1px solid black' }}>${order["Sub Total after all Discounts"]}</td>}
                <td style={{ border: '1px solid black' }}>{order["Fill %"]}</td>
                <td style={{ border: '1px solid black' }}>{order["Current User"]}</td>
                <td style={{ border: '1px solid black' }}>{order["No# of Detail lines"]}</td>
                <td style={{ border: '1px solid black' }}>{order["Percentage of Cons"]}</td>
                <td style={{ border: '1px solid black' }}>{priorityText}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td style={{ border: '1px solid black', textAlign: 'center' }}>Total SO's: {rowCount}</td>
            {isAdmin ? (
              <>
                <td style={{ border: '1px solid black', textAlign: 'center' }} colSpan="6"></td>
                <td style={{ border: '1px solid black', textAlign: 'center' }}>${calculateTotal()}</td>
                <td style={{ border: '1px solid black', textAlign: 'center' }} colSpan="5"></td>
              </>
            ) : (
              <td style={{ border: '1px solid black', textAlign: 'center' }} colSpan="11"></td>
            )}
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default WidgetOne;
