import { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const useWorkflowCheck = (userEmail) => {
    const [hasWorkflowAccess, setHasWorkflowAccess] = useState(false);
    const db = getFirestore();

    useEffect(() => {
        const fetchWorkflowAccessStatus = async () => {
            if (!userEmail) return;

            const docRef = doc(db, "workflowAccess", "emails");

            try {
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();

                    // Checking the correct field 'access' for the array of emails
                    const workflowEmails = Array.isArray(data.access) ? data.access : [];
                    if (workflowEmails.includes(userEmail)) {
                        setHasWorkflowAccess(true);
                    } else {
                        setHasWorkflowAccess(false);
                    }
                } else {
                    console.log("No workflow access email list found!");
                }
            } catch (error) {
                console.error("Error fetching workflow access emails:", error);
            }
        };

        fetchWorkflowAccessStatus();
    }, [userEmail, db]);

    return hasWorkflowAccess;
};

export default useWorkflowCheck;
