
import React from 'react';
import Nav from './Nav';
import WidgetFive from './WidgetFive';
import WidgetSix from './WidgetSix';
import Footer from './Footer';

const CombinedDropship = () => {
  return (
    <>

    <div className="combined-widgets-container wrapper">
      <div className="widget-container top">
        <WidgetFive />
      </div>
      <div className="gradient-divider"></div> {/* Divider element */}

      <div className="widget-container bottom">
        <WidgetSix />
      </div>
    </div>
    </>

  );
};

export default CombinedDropship;