import { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const useAdminCheck = (userEmail) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const db = getFirestore();

  useEffect(() => {
    const fetchAdminStatus = async () => {
      // Corrected path to point to the 'emails' document inside 'adminEmails' collection
      const docRef = doc(db, "adminEmails", "emails");

      try {
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();

          // Assuming 'admins' is the field name that holds the array of admin emails
          const adminEmails = data.admins;
          if (adminEmails && adminEmails.includes(userEmail)) {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        } else {
          console.log("No admin email list found!");
        }
      } catch (error) {
        console.error("Error fetching admin emails:", error);
      }
    };

    if (userEmail) {
      fetchAdminStatus();
    }
  }, [userEmail, db]);

  return isAdmin;
};

export default useAdminCheck;
