import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import API_URLS from '../config';
import useAdminCheck from './AuthContext/useAdminCheck';
import { useAuth } from './AuthContext/AuthContext';

function SalesTargetsValue() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [summaryData, setSummaryData] = useState({});
    const { currentUser } = useAuth();
    const isAdmin = useAdminCheck(currentUser?.email);

    useEffect(() => {
        const fetchSalesTargets = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const url = API_URLS.SALES_TARGETS_VALUE;
                const options = {
                    headers: {
                        'x-api-key': API_URLS.API_KEY
                    }
                };
                const response = await fetch(url, options);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const json = await response.json();
                setData(json);
            } catch (error) {
                setError(`Failed to fetch sales targets: ${error.message}`);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSalesTargets();
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            const dataItem = data[0];
            const diffToNCTarget = dataItem.NC_Target - dataItem.Cur_valofNC;
            const diffToCTarget = dataItem.C_Target - dataItem.Cur_valofC;

            const summary = {
                currentNC: Number(dataItem.Cur_PercentageOfNC).toFixed(2),
                projectedNC: Number(dataItem.Pred_PercentageofNC).toFixed(2),
                toTargetNC: Number(diffToNCTarget),
                currentC: Number(dataItem.cur_PercentageofC).toFixed(2),
                projectedC: Number(dataItem.Pred_PercentageofC).toFixed(2),
                toTargetC: Number(diffToCTarget),
                ncTarget: Number(dataItem.NC_Target).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                cTarget: Number(dataItem.C_Target).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                totalTarget: Number(dataItem.NC_Target + dataItem.C_Target).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                totalCurrentSales: Number(dataItem.Cur_valofC + dataItem.Cur_valofNC).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                totalProjectedSales: Number(dataItem.Pred_valofC + dataItem.Pred_valofNC).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                deltaSales: Number(dataItem.Pred_valofC + dataItem.Pred_valofNC - dataItem.Cur_valofC - dataItem.Cur_valofNC).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                ncTargetValue: dataItem.NC_Target,
                currentNCValue: dataItem.Cur_valofNC,
                projectedNCValue: dataItem.Pred_valofNC,
                cTargetValue: dataItem.C_Target,
                currentCValue: dataItem.Cur_valofC,
                projectedCValue: dataItem.Pred_valofC,
                totalCurrentPercentage: ((dataItem.Cur_valofNC + dataItem.Cur_valofC) / (dataItem.NC_Target + dataItem.C_Target) * 100).toFixed(2),
                totalProjectedPercentage: ((dataItem.Pred_valofNC + dataItem.Pred_valofC) / (dataItem.NC_Target + dataItem.C_Target) * 100).toFixed(2),
            };
            setSummaryData(summary);
        }
    }, [data, isAdmin]);

    const processData = (dataItem) => {
        const totalCurrentValue = dataItem.Cur_valofNC + dataItem.Cur_valofC;
        const totalProjectedValue = dataItem.Pred_valofNC + dataItem.Pred_valofC;
        const totalTargetValue = dataItem.NC_Target + dataItem.C_Target;

        const totalCurrentPercentage = (totalCurrentValue / totalTargetValue) * 100;
        const totalProjectedPercentage = (totalProjectedValue / totalTargetValue) * 100;

        return [
            {
                name: 'Projected Non Consignment',
                data: [{
                    y: dataItem.Pred_PercentageofNC - dataItem.Cur_PercentageOfNC,
                    actual: dataItem.Pred_PercentageofNC,
                    tooltipValue: dataItem.Pred_PercentageofNC,
                    curVal: dataItem.Cur_valofNC,
                    predVal: dataItem.Pred_valofNC
                }],
                color: 'lightblue',
                stack: 'NC'
            },
            {
                name: 'Current Non Consignment',
                data: [{
                    y: dataItem.Cur_PercentageOfNC,
                    actual: dataItem.Cur_PercentageOfNC,
                    tooltipValue: dataItem.Cur_PercentageOfNC,
                    curVal: dataItem.Cur_valofNC,
                    predVal: dataItem.Pred_valofNC
                }],
                color: '#85A8D1',
                stack: 'NC'
            },
            {
                name: 'Projected Consignment',
                data: [{
                    y: dataItem.Pred_PercentageofC - dataItem.cur_PercentageofC,
                    actual: dataItem.Pred_PercentageofC,
                    tooltipValue: dataItem.Pred_PercentageofC,
                    curVal: dataItem.Cur_valofC,
                    predVal: dataItem.Pred_valofC
                }],
                color: 'lightgreen',
                stack: 'C'
            },
            {
                name: 'Current Consignment',
                data: [{
                    y: dataItem.cur_PercentageofC,
                    actual: dataItem.cur_PercentageofC,
                    tooltipValue: dataItem.cur_PercentageofC,
                    curVal: dataItem.Cur_valofC,
                    predVal: dataItem.Pred_valofC
                }],
                color: '#63B665',
                stack: 'C'
            },
            {
                name: 'Total Projected',
                data: [{
                    y: totalProjectedPercentage - totalCurrentPercentage,
                    actual: totalProjectedPercentage,
                    tooltipValue: totalProjectedPercentage,
                    curVal: totalCurrentValue,
                    predVal: totalProjectedValue
                }],
                color: 'lightcoral',
                stack: 'Total'
            },
            {
                name: 'Total Current',
                data: [{
                    y: totalCurrentPercentage,
                    actual: totalCurrentPercentage,
                    tooltipValue: totalCurrentPercentage,
                    curVal: totalCurrentValue,
                    predVal: totalProjectedValue
                }],
                color: '#ff6961',
                stack: 'Total'
            }
        ];
    };

    const getOptions = (seriesData) => ({
        chart: {
            type: 'bar',
            events: {
                load: function () {
                    this.renderer.text('NC', 12, 140)
                        .css({
                            color: '#03a9f4',
                            fontSize: '13px',
                            fontWeight: 'bold',
                        })
                        .add();
                    this.renderer.text('C', 15, 190)
                        .css({
                            color: '#03a9f4',
                            fontSize: '13px',
                            fontWeight: 'bold',
                        })
                        .add();
                    this.renderer.text('Total', 12, 235)
                        .css({
                            color: '#03a9f4',
                            fontSize: '13px',
                            fontWeight: 'bold',
                        })
                        .add();
                }
            }
        },
        accessibility: {
            enabled: false
        },
        title: {
            text: 'Sales Targets'
        },
        xAxis: {
            categories: ['work'],
            labels: {
                style: {
                    color: 'white',
                    fontSize: '10px',
                    fontWeight: '500',
                },
            },
            title: {
                text: '',
                style: {
                    display: 'none'
                }
            }
        },
        yAxis: {
            min: 0,
            max: 200,
            title: {
                text: 'Percentages',
                style: {
                    color: '#666666',
                    fontSize: '12px',
                    fontWeight: 'bold'
                }
            }
        },
        tooltip: {
            formatter: function () {
                let tooltipValue = this.point.tooltipValue !== undefined ? this.point.tooltipValue : this.point.y;
                let percentageDisplay = `${Number(tooltipValue).toFixed(2)}%`;
                let tooltipText = `<b>${this.series.name}</b>: ${percentageDisplay}`;

                if (isAdmin) {
                    let currentDollarValue = this.point.curVal !== undefined ?
                        Number(this.point.curVal).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
                        'N/A';
                    let predictedDollarValue = this.point.predVal !== undefined ?
                        Number(this.point.predVal).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
                        'N/A';

                    tooltipText += `<br/>Current Value: $${currentDollarValue}`;
                    tooltipText += `<br/>Predicted Value: $${predictedDollarValue}`;
                }

                return tooltipText;
            }
        },
        plotOptions: {
            series: {
                stacking: 'normal'
            }
        },
        series: seriesData
    });

    const chartOptions = data.length > 0 ? getOptions(processData(data[0])) : {};

    const nonConsignmentProgress = summaryData.ncTargetValue ? (summaryData.currentNCValue / summaryData.ncTargetValue) * 100 : 0;
    const consignmentProgress = summaryData.cTargetValue ? (summaryData.currentCValue / summaryData.cTargetValue) * 100 : 0;

    const totalDelta = isAdmin ? `$${(summaryData.toTargetC + summaryData.toTargetNC).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : 'N/A';

    return (
        <div>
            {isLoading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            {!isLoading && !error && (
                <>
                    <div className="sales-target-card-container">
                        <div className="sales-target-card">
                            <h2>Non Consignment Target</h2>
                            {isAdmin && <p className="sales-target-value">{summaryData.ncTarget}</p>}
                            <div className="sales-target-inner-card">
                                <h3 className="sales-target-non-consignment">Non-Consignment</h3>
                                <p className="sales-target-percentage">Invoiced: <strong>{summaryData.currentNC}%</strong></p>
                                {isAdmin && <span>${Number(summaryData.currentNCValue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>}
                                <p className="sales-target-percentage">*Projected: <strong>{summaryData.projectedNC}%</strong></p>
                                {isAdmin && <span>${Number(summaryData.projectedNCValue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>}
                                <div className="sales-target-progress-bar">
                                    <div className="sales-target-progress" style={{ width: `${nonConsignmentProgress}%`, background: 'blue' }}></div>
                                </div>
                                {isAdmin && <p className="sales-target-delta">Delta: <strong>${Number(summaryData.toTargetNC).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong></p>}
                            </div>
                        </div>

                        <div className="sales-target-card">
                            <h2>Consignment Target</h2>
                            {isAdmin && <p className="sales-target-value">{summaryData.cTarget}</p>}
                            <div className="sales-target-inner-card">
                                <h3 className="sales-target-consignment">Consignment</h3>
                                <p className="sales-target-percentage">Invoiced: <strong>{summaryData.currentC}%</strong></p>
                                {isAdmin && <span>${Number(summaryData.currentCValue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>}
                                <p className="sales-target-percentage">*Projected: <strong>{summaryData.projectedC}%</strong></p>
                                {isAdmin && <span>${Number(summaryData.projectedCValue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>}
                                <div className="sales-target-progress-bar">
                                    <div className="sales-target-progress" style={{ width: `${consignmentProgress}%`, background: 'green' }}></div>
                                </div>
                                {isAdmin && <p className="sales-target-delta">Delta: <strong>${Number(summaryData.toTargetC).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong></p>}
                            </div>
                        </div>

                        <div className="sales-target-card">
                            <h2>Total Target</h2>
                            {isAdmin && <p className="sales-target-value">{summaryData.totalTarget}</p>} {/* Corrected value */}
                            <div className="sales-target-inner-card">
                                <h3>Total Target</h3>
                                <p className="sales-target-percentage">Invoiced: <strong>{summaryData.totalCurrentPercentage}%</strong></p>
                                {isAdmin && <span>${Number(summaryData.currentNCValue + summaryData.currentCValue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>}
                                <p className="sales-target-percentage">*Projected: <strong>{summaryData.totalProjectedPercentage}%</strong></p>
                                {isAdmin && <span>${Number(summaryData.projectedNCValue + summaryData.projectedCValue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>}
                                <div className="sales-target-progress-bar">
                                    <div className="sales-target-progress" style={{ width: `${summaryData.totalCurrentPercentage}%`, background: 'black' }}></div>
                                </div>
                                {isAdmin && <p className="sales-target-delta">Total Delta: <strong>{totalDelta}</strong></p>}
                            </div>
                        </div>
                    </div>
                    <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                    <div style={{ fontSize: '10px', marginTop: '10px', textAlign: 'center' }}>
                        <sup>
                            <strong>*Projected value</strong> is calculated by adding the subtotals of the orders in the following statuses:
                            <br />
                            CONSOLIDATED PICK, IN WAREHOUSE, MAJOR - IN WAREHOUSE, Pick Complete, Pick in Progress, Pick Slip Printed,
                            autopost, shipped AND also has a requested ship date in our current month.
                        </sup>
                    </div>
                </>
            )}
        </div>
    );
}

export default SalesTargetsValue;
