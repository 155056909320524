import React, { useEffect, useState } from 'react';
import API_URLS from '../config';

function Announcements({ preview, announcementIndex = 0 }) {
    const [announcements, setAnnouncements] = useState([]);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [announcementType, setAnnouncementType] = useState('All');

    useEffect(() => {
        const fetchAnnouncements = async () => {
            setIsLoading(true);
            setError(null);
            try {
                let url = API_URLS.ANNOUNCEMENTS;
                if (announcementType !== 'All') {
                    url += `?Announcement_type=${announcementType}`;
                }
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const text = await response.text();
                try {
                    const data = JSON.parse(text);
                    setAnnouncements(data);
                } catch (jsonError) {
                    throw new Error("Failed to parse JSON response");
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAnnouncements();
        const intervalId = setInterval(fetchAnnouncements, 25000); // Fetch every 25 seconds

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [announcementType]);

    const handleClose = () => setSelectedAnnouncement(null);
    const truncateText = (text, length) => text.length > length ? text.substring(0, length) + '...' : text;
    const handleTypeChange = (e) => setAnnouncementType(e.target.value);

    const renderContent = (text, imageSize = { width: '100px', height: '100px' }) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                if (part.match(/\.(gif)($|\?)/i)) {
                    return (
                        <img
                            src={part}
                            alt="GIF"
                            style={{
                                maxWidth: imageSize.width,
                                maxHeight: imageSize.height,
                                display: 'block',
                                margin: '10px 0'
                            }}
                            key={index}
                        />
                    );
                } else if (isValidHttpUrl(part)) {
                    return <a href={part} target="_blank" rel="noopener noreferrer" key={index}>{part}</a>;
                }
                return null;
            }
            return part;
        });
    };

    const isValidHttpUrl = (string) => {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    const announcementsToDisplay = preview ? announcements.slice(announcementIndex, announcementIndex + 3) : announcements;

    return (
        <>
            <div className="wrapper">
                <div>
                    {!preview && (
                        <div className="dropdown-container">
                            <select onChange={handleTypeChange} value={announcementType} className="type-selector">
                                <option value="All">All Types</option>
                                <option value="custom">Custom</option>
                                <option value="marketing">Marketing</option>
                                <option value="receiving">Receiving</option>
                                <option value="warehouse">Warehouse</option>
                            </select>
                            <div className="custom-arrow">▼</div>
                        </div>
                    )}

                    <div className={`announcements-container ${preview ? 'preview' : ''}`}>
                        {announcementsToDisplay.map((announcement, index) => (
                            <div className="announcement-card" key={index} onClick={() => !preview && setSelectedAnnouncement(announcement)} style={{ maxWidth: '300px', maxHeight: '300px' }}>
                                <h2 className="announcement-title">{announcement.Announcement_type.toUpperCase()} Announcement</h2>
                                <p className="announcement-text">{renderContent(truncateText(announcement.Announcement_text, 350))}</p>
                                <div className="announcement-poster">Posted by: {announcement.User_name}</div>
                            </div>
                        ))}

                        {selectedAnnouncement && !preview && (
                            <div className="modal-overlay" onClick={handleClose}>
                                <div className="modal-content" onClick={(e) => e.stopPropagation()} >
                                    <h2>{selectedAnnouncement.Announcement_type.toUpperCase()} Announcement</h2>
                                    <div className="modal-text-content">
                                        {selectedAnnouncement.Announcement_text.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {renderContent(line, { width: '250px', height: '250px' })}<br />
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    <div>
                                        <br />
                                        <small>Posted by: {selectedAnnouncement.User_name}</small>
                                    </div>
                                    <button className="announcement-modal-close-button" onClick={handleClose}>🆇</button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Announcements;
