
import React from 'react';
import Nav from './Nav';
import WidgetThree from './WidgetThree';
import WidgetFour from './WidgetFour';
import Footer from './Footer';

const CombinedIndependent = () => {
  return (
    <>

    <div className="combined-widgets-container wrapper">
      <div className="widget-container top">
        <WidgetThree />
      </div>
      <div className="gradient-divider"></div> {/* Divider element */}

      <div className="widget-container bottom">
        <WidgetFour />
      </div>
    </div>
    </>

  );
};

export default CombinedIndependent;
