import React, { useState, useEffect } from 'react';
import { auth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from './Firebase';
import { useNavigate } from 'react-router-dom';
import googleLogo from '../assets/googleLogo.png'

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // This effect uses the Firebase onAuthStateChanged observer
    // to listen for changes in the authentication state.
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        // If there's a user, that means they're already logged in,
        // so we navigate them away from the login page.
        navigate('/Majors');
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [navigate]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/Majors');
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate('/Majors');
    } catch (error) {
      console.error('Google sign-in failed:', error);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form-container">
        <h2 className="login-form-title">Dashboard Login</h2>
        {/* <div className="login-input-group">
          <input
            type="text"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="login-input"
            required
          />
          <span className={`placeholder-span ${email ? 'active' : ''}`}>Username</span>
        </div>
        <div className="login-input-group">
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
            required
          />
          <span className={`placeholder-span ${password ? 'active' : ''}`}>Password</span>
        </div> */}
        <div className="login-button-container">
          {/* <button type="submit" className="login-button">Login</button> */}
          <button onClick={handleGoogleSignIn} className="google-sign-in-button">
          <img src={googleLogo} alt="Google" className="google-logo" />

            Sign in with Google
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;


