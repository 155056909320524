import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { useMediaQuery } from 'react-responsive';
import 'chart.js/auto';

function WidgetSeven() {
  const [orders, setOrders] = useState([]);

  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const chartHeight = isSmallScreen ? 190 : 250;

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(process.env.REACT_APP_API_SUMMARY_PRIMARY);
      const data = await response.json();
      setOrders(data.data);
    };

    fetchData();
    const intervalId = setInterval(fetchData, 300000);

    return () => clearInterval(intervalId);
  }, []);

  if (orders.length === 0) return "Loading...";

  const chartData1 = {
    labels: ['Accepted Orders', 'Orders in Warehouse', 'New Vendor DS', 'New EDI', 'Consolidated Pick', 'Majors in Warehouse'],
    datasets: [{
      label: 'Order Data',
      data: [
        orders[0]["# of Accepted Orders"],
        orders[0]["# of orders in warehouse"],
        orders[0]["# of new Vendor DS"],
        orders[0]["# of New EDI"],
        orders[0]["# of Consolidated Pick"],
        orders[0]["# of Majors in Warehouse"],
      ],
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1
    }]
  };

  const chartData2 = {
    labels: ['Pick in Progress IND', 'Pick in Progress Major', 'Pick Completed Majors', 'Completed Pick IND'],
    datasets: [{
      label: 'Pick Data',
      data: [
        orders[0]["# of Pick in progress IND"],
        orders[0]["# of Pick in Progress Major"],
        orders[0]["# of pick completed Majors"],
        orders[0]["# of Completed Pick IND"]
      ],
      backgroundColor: 'rgba(153, 102, 255, 0.6)',
      borderColor: 'rgba(153, 102, 255, 1)',
      borderWidth: 1
    }]
  };

  const chartData3 = {
    labels: ['Shipped Majors', 'Shipped IND', 'Ready to Ship', 'ASN Sent', 'Orders to Post'],
    datasets: [{
      label: 'Shipping Data',
      data: [
        orders[0]["# of Shipped Majors"],
        orders[0]["# of Shipped IND"],
        orders[0]["# of Ready to Ship"],
        orders[0]["# of ASN Sent"],
        orders[0]["# of Orders to Post"]
      ],
      backgroundColor: 'rgba(255, 159, 64, 0.6)',
      borderColor: 'rgba(255, 159, 64, 1)',
      borderWidth: 1
    }]
  };

  return (
    <>
      <div className='widget seven flex-item'>
        <div className="widget-container">
          {/* Custom Header */}
          <div className="Header">
            <h2>Summary</h2>
          </div>

          {/* Charts Container */}
          <div className="charts-container">
            {/* First Chart */}
            <div className="chart-container" style={{ height: `${chartHeight}px` }}>
              <h3>Order Summary</h3>
              <Bar data={chartData1} options={{ responsive: true, maintainAspectRatio: false }} />
            </div>

            {/* Second Chart */}
            <div className="chart-container" style={{ height: `${chartHeight}px` }}>
              <h3>Pick Summary</h3>
              <Bar data={chartData2} options={{ responsive: true, maintainAspectRatio: false }} />
            </div>

            {/* Third Chart */}
            <div className="chart-container" style={{ height: `${chartHeight}px` }}>
              <h3>Shipping Summary</h3>
              <Bar data={chartData3} options={{ responsive: true, maintainAspectRatio: false }} />
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .widget.seven.flex-item {
          overflow: hidden;
        }

        .charts-container {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          justify-content: center;
          min-height: 300px;
        }

        .chart-container {
          flex: 1 1 30%;
          min-width: 300px;
          max-width: 500px;
        }

        @media (max-width: 768px) {
          .charts-container {
            flex-direction: column;
            align-items:center;
            gap:15px;
            height: 80vh; /* Adjust height as needed */
            overflow-y: auto;
          }

          .chart-container {
            flex: 1 1 auto;
            max-width: 100%;
            min-width:40%;
          }
        }

        @media (max-width: 490px) {
          .chart-container {
            flex: 1 1 auto;
            max-width: 100%;
          }
        }
      `}</style>
    </>
  );
}

export default WidgetSeven;
