import React, { useState, useEffect } from 'react';
import loadingGif from '../assets/slothGif.gif'; // Ensure this path is correct
import useAdminCheck from './AuthContext/useAdminCheck';
import { useAuth } from './AuthContext/AuthContext';

function WidgetFive() {
  const [orders, setOrders] = useState([]);
  const [displayOrders, setDisplayOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const { currentUser } = useAuth();
  const isAdmin = useAdminCheck(currentUser?.email);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        let response = await fetch(process.env.REACT_APP_API_DROPSHIP_1_PRIMARY);
        if (!response.ok) throw new Error('Network response was not ok');
        let data = await response.json();

        if (data && data.data && data.data.length > 0) {
          setOrders(data.data);
          initiateGlitchEffect(data.data);
        } else {
          throw new Error('No data returned');
        }
      } catch (error) {
        console.error('First fetch failed or no data:', error);
        try {
          let response = await fetch(process.env.REACT_APP_API_DROPSHIP_1_SECONDARY);
          if (!response.ok) throw new Error('Network response was not ok');
          let data = await response.json();

          if (data && data.data && data.data.length > 0) {
            setOrders(data.data);
            initiateGlitchEffect(data.data);
          } else {
            setOrders([]);
          }
        } catch (error) {
          console.error('Second fetch failed or no data:', error);
          setOrders([]);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 120000);

    return () => clearInterval(intervalId);
  }, []);

  const initiateGlitchEffect = (data) => {
    let steps = 10;
    let interval = 100;

    for (let i = 0; i <= steps; i++) {
      setTimeout(() => {
        const tempData = data.map(order => {
          let tempOrder = {};
          for (const key in order) {
            tempOrder[key] = i === steps ? order[key] : Math.floor(Math.random() * 1000000);
          }
          return tempOrder;
        });
        setDisplayOrders(tempData);
      }, i * interval);
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedData = [...displayOrders].sort((a, b) => {
      let aValue = a[key];
      let bValue = b[key];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      if (key === 'Current User') {
        aValue = aValue || ''; // Handle null or undefined values
        bValue = bValue || ''; // Handle null or undefined values
      }

      if (!isNaN(Date.parse(aValue)) && !isNaN(Date.parse(bValue))) {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
      } else if (!isNaN(aValue) && !isNaN(bValue)) {
        aValue = parseFloat(aValue);
        bValue = parseFloat(bValue);
      }

      if (aValue < bValue) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setDisplayOrders(sortedData);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (orders.length === 0) {
    return <img src={loadingGif} alt="Loading" className='loadingGif' />;
  }

  const calculateTotal = () => {
    return displayOrders.reduce((acc, order) => {
      return acc + parseFloat(order["Sub Total after all Discounts"] || 0);
    }, 0).toFixed(2);
  };

  const rowCount = displayOrders.length;

  return (
    <div className='widget one flex-item '>
      <div>
        <div className="Header">
          <h2>Dropship in progress</h2>
        </div>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('SO#')}>SO# {sortConfig.key === 'SO#' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('PO#')}>PO# {sortConfig.key === 'PO#' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Customer Code')}>Customer Code {sortConfig.key === 'Customer Code' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Order Date')}>Order Date {sortConfig.key === 'Order Date' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Order Status')}>Order Status {sortConfig.key === 'Order Status' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Ship Name')}>Ship Name {sortConfig.key === 'Ship Name' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Requested Ship Date')}>Requested Ship Date {sortConfig.key === 'Requested Ship Date' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              {isAdmin && (
                <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Sub Total after all Discounts')}>
                  Sub Total after all Discounts {sortConfig.key === 'Sub Total after all Discounts' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}
                </th>
              )}
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Fill %')}>Fill % {sortConfig.key === 'Fill %' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Current User')}>Current User {sortConfig.key === 'Current User' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('No# of Detail lines')}>No# of Detail lines {sortConfig.key === 'No# of Detail lines' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Percentage of Cons')}>Percentage of Cons {sortConfig.key === 'Percentage of Cons' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
            </tr>
          </thead>
          <tbody>
            {displayOrders.map((order, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid black' }}>{order["SO#"]}</td>
                <td style={{ border: '1px solid black' }}>{order["PO#"]}</td>
                <td style={{ border: '1px solid black' }}>{order["Customer Code"]}</td>
                <td style={{ border: '1px solid black' }}>{order["Order Date"]}</td>
                <td style={{ border: '1px solid black' }}>{order["Order Status"]}</td>
                <td style={{ border: '1px solid black' }}>{order["Customer Name"]}</td>
                <td style={{ border: '1px solid black' }}>{order["Requested Ship Date"]}</td>
                {isAdmin && <td style={{ border: '1px solid black' }}>${order["Sub Total after all Discounts"]}</td>}
                <td style={{ border: '1px solid black' }}>{order["Fill %"]}</td>
                <td style={{ border: '1px solid black' }}>{order["Current User"] || ''}</td>
                <td style={{ border: '1px solid black' }}>{order["No# of Detail lines"]}</td>
                <td style={{ border: '1px solid black' }}>{order["Percentage of Cons"]}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td style={{ border: '1px solid black', textAlign: 'center' }}>Total SO's: {rowCount}</td>
              <td style={{ border: '1px solid black', textAlign: 'center' }} colSpan="6"></td>
              {isAdmin && (
                <>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>${calculateTotal()}</td>
                  <td style={{ border: '1px solid black', textAlign: 'center' }} colSpan="4"></td>
                </>
              )}
              {!isAdmin && <td style={{ border: '1px solid black', textAlign: 'center' }} colSpan="5"></td>}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

export default WidgetFive;
